// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatures-module--root--1GOzQ";
export var box__fZc5K = "PlasmicFeatures-module--box__fZc5K--3GmRp";
export var header = "PlasmicFeatures-module--header--1Ld5D";
export var section = "PlasmicFeatures-module--section--AVnp2";
export var box__mkmB = "PlasmicFeatures-module--box__mkmB--eI3EI";
export var box__jPqCx = "PlasmicFeatures-module--box__jPqCx--1G3ed";
export var box__mJLjQ = "PlasmicFeatures-module--box__mJLjQ--kvRxn";
export var box___8QVjb = "PlasmicFeatures-module--box___8QVjb--2d7PD";
export var bullet__xs0KQ = "PlasmicFeatures-module--bullet__xs0KQ--10NFf";
export var bullet__qe0Gu = "PlasmicFeatures-module--bullet__qe0Gu--3J3BP";
export var bullet__g6Wx = "PlasmicFeatures-module--bullet__g6Wx--1qo5G";
export var box__kEQw = "PlasmicFeatures-module--box__kEQw--10wMX";
export var box__d6Y51 = "PlasmicFeatures-module--box__d6Y51--waB8f";
export var box__znAyv = "PlasmicFeatures-module--box__znAyv--2XywN";
export var img__mBrGz = "PlasmicFeatures-module--img__mBrGz--UwAbV";
export var img__ub3Hw = "PlasmicFeatures-module--img__ub3Hw--1vGko";
export var box__ldM5V = "PlasmicFeatures-module--box__ldM5V--3__tH";
export var box__bJfQi = "PlasmicFeatures-module--box__bJfQi--5kapw";
export var img___1Bw2 = "PlasmicFeatures-module--img___1Bw2--1l8JR";
export var img__vToCo = "PlasmicFeatures-module--img__vToCo--1_B3x";
export var box__gZqp4 = "PlasmicFeatures-module--box__gZqp4--3n4X9";
export var box___1L6Zp = "PlasmicFeatures-module--box___1L6Zp--2fPRM";
export var box__fpKbC = "PlasmicFeatures-module--box__fpKbC--1GUJr";
export var bullet__xiSxF = "PlasmicFeatures-module--bullet__xiSxF--15y2E";
export var bullet__zb6Oj = "PlasmicFeatures-module--bullet__zb6Oj--357hp";
export var bullet__rola9 = "PlasmicFeatures-module--bullet__rola9--1J23J";
export var box__hgdFu = "PlasmicFeatures-module--box__hgdFu--3pDx5";
export var box__zVYj = "PlasmicFeatures-module--box__zVYj--139yh";
export var homeCta = "PlasmicFeatures-module--homeCta--3_pJJ";
export var footer = "PlasmicFeatures-module--footer--2Pg4E";